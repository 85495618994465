import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logoImg from '../assets/sun.png'

import Skills from './Skills'
const Hero = () => {
  return (
    <>

      <div className='back'></div>
      <div id='hero' className='hero'>
        <Row className='sun'>

        </Row>
        <Row className='content-wrapper'>
          <Row className='sun'>
            <Col className='col-12 col-md-2'>
              <img src={logoImg} alt="logo" />

            </Col>
          </Row>
          <Col className='col-12 col-md-12'>
            <div className="text-wrapper">
              <div className="hello">
                {/* <h4>Hi, my name is</h4> */}
              </div>
              <div>
                {/* <h1 className="header">Hi, I&#39;m Fasai</h1> */}
                <p className="header">Hi, I&#39;m Fasai</p>
                {/* <h1 className="lastname">Chunchuasuparerk,</h1> */}
              </div>
              <p>which means clear sky in Thai ☀️. I am a junior developer based in London and I am currently looking for development opportunities.</p>
            </div>

          </Col>
          {/* <Col className='col-12 col-md-2'>
            <img src={logoImg} alt="logo" />
          </Col> */}
        </Row>
      </div>
      {/* <Skills classsName='hero-skills'></Skills> */}
      {/* <div className='nav-links'>
        <a href="#about" className='py-2 border-bottom-2'>About</a>
        <a href="#skills" className='py-2 border-bottom-2'>Skills</a>
        <a href="#projects" className='py-2 border-bottom-2'>Projects</a>
        <a href="#experience" className='py-2 border-bottom-2'>Experience</a>
        <a href="#contact" className='py-2 border-bottom-2'>Contact</a>
      </div> */}

    </>

  )
}

export default Hero