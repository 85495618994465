import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Skills = () => {
  return (
    <div id='skills' className='skills section-container '>
      <div className="section-wrapper">
        <Row className='content-wrapper'>
          <div className="header-wrapper text-center">

            <h1 className='section-header'>Skills</h1>
          </div>

          <div className="icons-container">
            <div>
              <i className="devicon-html5-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" /> */}

              <p>HTML5</p>
            </div>
            <div>
              <i className="devicon-css3-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" /> */}

              <p>CSS3</p>
            </div>
            <div>
              {/* <i className="devicon-javascript-plain colored"></i> */}
              <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />

              <p>JavaScript</p>
            </div>
            <div>
              <i className="devicon-react-original colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" /> */}

              <p>React</p>
            </div>

            <div>
              {/* <i className="devicon-python-plain colored"></i> */}
              <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" />

              <p>Python</p>
            </div>
            <div>
              <i className="devicon-django-plain"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg" /> */}

              <p>Django</p>
            </div>
            <div>
              <i className="devicon-postgresql-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" /> */}

              <p>PostgreSQL</p>
            </div>
            <div>
              <i className="devicon-bootstrap-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" /> */}

              <p>Bootstrap</p>
            </div>

            <div>
              <i className="devicon-nodejs-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" /> */}

              <p>Node.js</p>
            </div>
            <div>
              <i className="devicon-express-original colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" /> */}

              <p>Express</p>
            </div>
            <div>
              <i className="devicon-mongodb-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg" /> */}

              <p>MongoDB</p>
            </div>
            <div>
              <i className="devicon-sass-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" /> */}

              <p>Sass</p>
            </div>

            <div>
              <i className="devicon-yarn-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/yarn/yarn-original.svg" /> */}

              <p>Yarn</p>
            </div>
            <div>
              <i className="devicon-npm-original-wordmark colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg" /> */}

              <p>NPM</p>
            </div>
            <div>
              <i className="devicon-heroku-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain.svg" /> */}

              <p>Heroku</p>
            </div>
            <div>
              <i className="devicon-github-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" /> */}

              <p>GitHub</p>
            </div>
            <div>
              <i className="devicon-rstudio-plain colored"></i>
              {/* <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rstudio/rstudio-original.svg" /> */}

              <p>RStudio</p>
            </div>
            <div>
              {/* <i className="devicon-figma-plain colored"></i> */}
              <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg" />

              <p>Figma</p>
            </div>
          </div>
        </Row>
      </div>

    </div>
  )
}

export default Skills