import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import fasaiImg from '../assets/fasai.jpeg'
import Skills from './Skills'
const About = () => {
  return (
    <div id='about' className='about-container'>
      <div className=" about section-wrapper">
        {/* <div className="background"></div> */}
        <Row className='content-wrapper'>
          <div className='header-wrapper'>
            <h1 className='section-header'>About Me</h1>

          </div>
          {/* <Col className='col-12 col-xl-7'> */}
          <Col className='col-12 col-lg-9'>
            <div className="text-wrapper">

              <p>I am a junior developer with a background in business analytics,
                technical support, customer service and sales. These experiences have led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>

              <p>My experiences made me realize that problem-solving through programming enables me to exercise my natural curiousity and creativity in the format that brings me joy. I decided to gain a deeper understanding in software development at General Assembly, and continue my journey with the goal of being able to do what I love everyday. </p>

              <p>I am now looking for opportunities to create value with what I am passionate about and apply my skill sets through the role of a developer. </p>



            </div>

          </Col>
          <Col className='col-12 col-lg-3 image-wrapper mt-3 mt-lg-0'>
            <img src={fasaiImg} alt="logo" />
          </Col>

          {/* </Col> */}
          {/* 
          <Col className='col-12 col-xl-5'>
            <Skills></Skills>
          </Col> */}
        </Row>
      </div>

    </div>
  )
}

export default About