import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import fasaiImg from '../assets/fasai.jpeg'

import Timeline from './Timeline.js'
const Experience = () => {
  return (
    <div id='experience' className=' section-container'>
      <div className=" experience section-wrapper">
        <div className='header-wrapper'>
          <h1 className='section-header ms-sm-5'>Education & Experience <hr /></h1>
        </div>

        <Row className='content-wrapper'>
          {/* <Col className='col-12 col-xl-3 exp-wrapper'>
            <h4>Software Engineering Student</h4>
            <h5>General Assembly</h5>
            <h5>Jun 2022 - Sept 2022</h5>
            <p>I am a junior developer with background in business analytics,
              technical support, customer service and sales. These experiences has led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>
          </Col>
          <Col className='col-12 col-xl-3 exp-wrapper'>
            <h4>Sales Executive</h4>
            <h5>Jun 2022 - Sept 2022</h5>
            <p>I am a junior developer with background in business analytics,
              technical support, customer service and sales. These experiences has led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>
          </Col>
          <Col className='col-12 col-xl-3 exp-wrapper'>
            <h4>International Relationship Manager </h4>
            <h5>Jun 2022 - Sept 2022</h5>
            <p>I am a junior developer with background in business analytics,
              technical support, customer service and sales. These experiences has led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>
          </Col>
          <Col className='col-12 col-xl-3 exp-wrapper'>
            <h4>Technical & Consulting Engineer</h4>
            <h5>Jun 2022 - Sept 2022</h5>
            <p>I am a junior developer with background in business analytics,
              technical support, customer service and sales. These experiences has led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>
          </Col> */}

        </Row>
        <Timeline></Timeline>
      </div>

    </div>
  )
}

export default Experience