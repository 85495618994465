import { useState, useEffect } from 'react'
import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import fasaiImg from '../assets/fasai.jpeg'
import Gallery from 'react-photo-gallery'
import { render } from 'react-dom'
import { FaMusic, FaCameraRetro } from 'react-icons/fa'
import { MdTheaterComedy } from 'react-icons/md'


import Timeline from './Timeline.js'
const Interest = () => {

  const [hideSection, setHideSection] = useState(true)
  const [photos, setPhotos] = useState([])
  // let photos = [

  //   // {
  //   //   src: 'https://i.imgur.com/fkBGsLk.jpg',
  //   //   width: 3,
  //   //   height: 4,
  //   // },
  //   {
  //     src: 'https://i.imgur.com/9kDprc7.jpg',
  //     width: 3,
  //     height: 4,
  //   },

  //   {
  //     src: 'https://i.imgur.com/RPagKr1.jpg',
  //     width: 3,
  //     height: 4,
  //   },
  //   // {
  //   //   src: 'https://i.imgur.com/i25gGgu.jpg',
  //   //   width: 3,
  //   //   height: 4,
  //   // },
  //   {
  //     src: 'https://i.imgur.com/1r4GXU5.jpg',
  //     width: 3,
  //     height: 4,
  //   },
  //   {
  //     src: 'https://i.imgur.com/WJftO41.jpg',
  //     width: 4,
  //     height: 3,
  //   },

  //   {
  //     src: 'https://i.imgur.com/188DRBz.jpg',
  //     width: 3,
  //     height: 4,
  //   },

  //   {
  //     src: 'https://i.imgur.com/CcdMkED.jpg',
  //     width: 4,
  //     height: 3,
  //   },
  //   {
  //     src: 'https://i.imgur.com/VETfbQY.jpg',
  //     width: 1,
  //     height: 1,
  //   },
  //   {
  //     src: 'https://i.imgur.com/93a95Et.jpg',
  //     width: 3,
  //     height: 4,
  //   },
  //   // {
  //   //   src: 'https://i.imgur.com/Q5SGIeX.jpg',
  //   //   width: 3,
  //   //   height: 4,
  //   // },

  //   // {
  //   //   src: 'https://i.imgur.com/nAOJOnf.jpg',
  //   //   width: 3,
  //   //   height: 4,
  //   // },




  //   {
  //     src: 'https://i.imgur.com/zvayZtl.png',
  //     width: 3,
  //     height: 4,
  //   },

  //   {
  //     src: 'https://i.imgur.com/SsyKbGV.jpg',
  //     width: 4,
  //     height: 3,
  //   },
  //   {
  //     src: 'https://i.imgur.com/ksnT2uj.jpg',
  //     width: 4,
  //     height: 3,
  //   },
  //   {
  //     src: 'https://i.imgur.com/WWf1rcN.jpg',
  //     width: 3,
  //     height: 4,
  //   }
  // ]

  useEffect(() => {
    if (hideSection === true) {
      setPhotos([
        {
          src: 'https://i.imgur.com/9kDprc7.jpg',
          width: 3,
          height: 4,
        },
    
        {
          src: 'https://i.imgur.com/RPagKr1.jpg',
          width: 3,
          height: 4,
        },
        {
          src: 'https://i.imgur.com/1r4GXU5.jpg',
          width: 3,
          height: 4,
        },
        {
          src: 'https://i.imgur.com/WJftO41.jpg',
          width: 4,
          height: 3,
        },
    
        {
          src: 'https://i.imgur.com/188DRBz.jpg',
          width: 3,
          height: 4,
        },
    
        {
          src: 'https://i.imgur.com/CcdMkED.jpg',
          width: 4,
          height: 3,
        },
        {
          src: 'https://i.imgur.com/VETfbQY.jpg',
          width: 1,
          height: 1,
        },
        {
          src: 'https://i.imgur.com/93a95Et.jpg',
          width: 3,
          height: 4,
        }
      ])
    } else {
      setPhotos([

        // {
        //   src: 'https://i.imgur.com/fkBGsLk.jpg',
        //   width: 3,
        //   height: 4,
        // },
        {
          src: 'https://i.imgur.com/9kDprc7.jpg',
          width: 3,
          height: 4,
        },
    
        {
          src: 'https://i.imgur.com/RPagKr1.jpg',
          width: 3,
          height: 4,
        },
        // {
        //   src: 'https://i.imgur.com/i25gGgu.jpg',
        //   width: 3,
        //   height: 4,
        // },
        {
          src: 'https://i.imgur.com/1r4GXU5.jpg',
          width: 3,
          height: 4,
        },
        {
          src: 'https://i.imgur.com/WJftO41.jpg',
          width: 4,
          height: 3,
        },
    
        {
          src: 'https://i.imgur.com/188DRBz.jpg',
          width: 3,
          height: 4,
        },
    
        {
          src: 'https://i.imgur.com/CcdMkED.jpg',
          width: 4,
          height: 3,
        },
        {
          src: 'https://i.imgur.com/VETfbQY.jpg',
          width: 1,
          height: 1,
        },
        {
          src: 'https://i.imgur.com/93a95Et.jpg',
          width: 3,
          height: 4,
        },
        // {
        //   src: 'https://i.imgur.com/Q5SGIeX.jpg',
        //   width: 3,
        //   height: 4,
        // },
    
        // {
        //   src: 'https://i.imgur.com/nAOJOnf.jpg',
        //   width: 3,
        //   height: 4,
        // },
    
    
    
    
        {
          src: 'https://i.imgur.com/zvayZtl.png',
          width: 3,
          height: 4,
        },
    
        {
          src: 'https://i.imgur.com/SsyKbGV.jpg',
          width: 4,
          height: 3,
        },
        {
          src: 'https://i.imgur.com/ksnT2uj.jpg',
          width: 4,
          height: 3,
        },
        {
          src: 'https://i.imgur.com/WWf1rcN.jpg',
          width: 3,
          height: 4,
        }
      ])
    }
  }, [hideSection])



  return (
    <div id='' className='interest-section-container'>
      <div id='interest' className=' section-container'>
        <div className=" interest section-wrapper">
          {/* <button className='button-36' onClick={() => hideSection ? setHideSection(false) : setHideSection(true)}>{hideSection ? 'See More!' : 'See Less'}</button> */}
          <Row className='content-wrapper'>
            <div className='header-wrapper'>
              <h1 className='section-header'>A Little More About me...</h1>
            </div>
            <Col className='col-12 col-sm-6 exp-wrapper'>
              <h4><FaMusic />  Music</h4>
              {/* <h5>General Assembly</h5>
            <h5>Jun 2022 - Sept 2022</h5> */}
              <p>I am a classically trained pianist, and can play some other instruments including flute, violin, guitar, and drums </p>
            </Col>
            <Col className='col-12 col-sm-6 exp-wrapper'>
              <h4><MdTheaterComedy /> Choir and Theatre</h4>
              <p>I love to sing in choirs and was a member of the national Thai Youth Choir. I also performed as a choir and ensemble for operas such as The Magic Flute, Brundibhar, and more.</p>
            </Col>
            <Col className='col-12'>
              <h4><FaCameraRetro /> Photography</h4>
              <h5></h5>
              <p>I love traveling, taking walks and taking photos along the way. Here are some of my photos: </p>
              {/* <Gallery photos={photos} /> */}
            </Col>

            {/* <Col className='col-12 col-xl-3 exp-wrapper'>
            <h4>International Relationship Manager </h4>
            <h5>Jun 2022 - Sept 2022</h5>
            <p>I am a junior developer with background in business analytics,
              technical support, customer service and sales. These experiences has led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>
          </Col>
          <Col className='col-12 col-xl-3 exp-wrapper'>
            <h4>Technical & Consulting Engineer</h4>
            <h5>Jun 2022 - Sept 2022</h5>
            <p>I am a junior developer with background in business analytics,
              technical support, customer service and sales. These experiences has led me to become a curious analytical-thinker with the capacity to grasp new knowledge outside my expertise and apply them to create solutions that match both client and business needs. </p>
          </Col> */}

          </Row>

          {/* <Timeline></Timeline> */}

        </div>
        <div className='gallery-wrapper mx-5 mw-75'>

          <Gallery photos={photos} />
          <button className='button-36' onClick={() => hideSection ? setHideSection(false) : setHideSection(true)}>{hideSection ? 'See More!' : 'See Less'}</button>

        </div>
      </div>
    </div>

  )
}
// render(<Interest />, document.getElementById('interest'))
export default Interest