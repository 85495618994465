import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'

const PageNavBar = () => {
  return (
    // <NavBar className="bg-gradient" expand="md">
    //   <NavBar.Brand to="/"><span className="logo ms-3">☀️ FASAI C.</span></NavBar.Brand>
    //   <NavBar.Toggle aria-controls='basic-navbar-nav'></NavBar.Toggle>
    //   <NavBar.Collapse id="basic-navbar-nav" className='justify-content-end'>
    //     <a href="#about" className='py-2 border-bottom-2 ms-2'>About</a>
    //     <a href="#skills" className='py-2 border-bottom-2 ms-2'>Skills</a>
    //     <a href="#projects" className='py-2 border-bottom-2 ms-2'>Projects</a>
    //     <a href="#experience" className='py-2 border-bottom-2 ms-2'>Experience</a>
    //     <a href="#contact" className='py-2 border-bottom-2 ms-2 me-2'>Contact</a>
    //   </NavBar.Collapse>
    // </NavBar>
    <NavBar className="navigation text-end text-sm-center" expand='md'>
      <NavBar.Brand to="/"></NavBar.Brand>
      <NavBar.Toggle aria-controls='basic-navbar-nav'></NavBar.Toggle>
      <NavBar.Collapse id="basic-navbar-nav" className='justify-content-end'>
        <a href="#about" className='py-2 border-bottom-2 me-3 me-sm-0 ms-3 d-block '>About</a>
        <a href="#skills" className='py-2 border-bottom-2 me-3 me-sm-0 ms-3 d-block'>Skills</a>
        <a href="#projects" className='py-2 border-bottom-2 me-3 me-sm-0 ms-3 d-block'>Projects</a>
        <a href="#experience" className='py-2 border-bottom-2 me-3 me-sm-0 ms-3 d-block'>Experience</a>
        <a href="#interest" className='py-2 border-bottom-2 me-3 me-sm-0 ms-3 d-block'>Interest</a>
        <a href="#contact" className='py-2 border-bottom-2 ms-3 me-3 d-block'>Contact</a>
      </NavBar.Collapse>
    </NavBar>
  )
}

export default PageNavBar