import { MdEmail } from 'react-icons/md'

const Footer = () => {

  return (
    <footer className="footer white">
      <div className=" footer-container text-center">
        <h4>Contact</h4>

        {/* <p>I am open for any development opportunities!</p> */}
        <p className=''>Feel free to contact me if you would like to have a chat!</p>
        <div className="contact-links">

          <a href='mailto: fasaic.113@gmail.com'><MdEmail /></a>
          <a href='https://www.linkedin.com/in/fasaic/'><i className="devicon-linkedin-plain colored"></i></a>
          <a href='https://github.com/fasaic'><i className="devicon-github-plain colored"></i></a>
        </div>
        <p className='credit'>&copy; Fasai Chunchuasuparerk 2022</p>
      </div>
    </footer>
  )
}

export default Footer