import { useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FaGraduationCap, FaBriefcase, FaCaretDown } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import buttonHasType from 'eslint-plugin-react/lib/rules/button-has-type'

const Timeline = () => {
  const [hideSection, setHideSection] = useState(false)

  return (
    <VerticalTimeline lineColor={'#DCDCDC'}>
      {/* GENERAL ASSEMBLY */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: '#fff', color: '#fff', borderTop: '2px solid #8ECAE6' }}
        contentArrowStyle={{ borderRight: '7px solid  #8ECAE6' }}
        date="Jun 2022 - Sept 2022"
        iconStyle={{ background: '#219EBC', color: 'white' }}
        icon={<FaGraduationCap />}
      >
        <h3 className="vertical-timeline-element-title">Software Engineering Immersive</h3>
        <h4 className="vertical-timeline-element-subtitle">General Assembly <span>- London, UK</span></h4>
        <p>
          Completed a 12-week immersive course that covers the fundamentals of full-stack development. It includes 480 hours of live lectures, daily standups, daily homework, and projects.
        </p>
      </VerticalTimelineElement>

      {/* VDIT Solutions */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Mar 2022 - May 2022"
        iconStyle={{ background: '#FFB703', color: '#fff' }}
        icon={<FaBriefcase />}
        contentStyle={{ background: '#fff', color: '#fff', borderTop: '2px solid #FFB703' }}
        contentArrowStyle={{ borderRight: '7px solid  #FFB703' }}
      >
        <h3 className="vertical-timeline-element-title">Sales Executive</h3>
        <h4 className="vertical-timeline-element-subtitle">VDIT Solutions <span>- London, UK</span> </h4>
        {/* <p>Proactively introduced and provided software solutions and services to restaurant and spa businesses in the UK. I also Provided customers with cost-benefit analysis of the product to match customer&#39;s needs and was able to close major deals.</p> */}
        <ul>
          <li><p>Proactively introduced and provided software solutions and services to restaurant and spa businesses in the UK</p></li>
          <li><p>Provided customers with cost-benefit analysis of the product to match customer&#39;s needs and closed major deals</p></li>
          <li><p>Marketed the product through the company&#39;s social media account</p></li>

        </ul>
      </VerticalTimelineElement>


      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Jan 2022 - Jan 2022"
        iconStyle={{ background: '#219EBC', color: '#fff' }}
        icon={<FaGraduationCap />}
        contentStyle={{ background: '#fff', color: '#fff', borderTop: '3px solid #8ECAE6' }}
        contentArrowStyle={{ borderRight: '7px solid  #219EBC' }}

      >
        <h3 className="vertical-timeline-element-title">MSc Business Analytics</h3>
        <h4 className="vertical-timeline-element-subtitle">University of Exeter <span>- Exeter, UK</span></h4>
        <p>Key modules include: </p>

        <p>
          {/* User Experience, Visual Design */}
          Programming for Business Analytics, Database Technologies, Statistics and Mathematics, Analytics and Visualization, Operation Analytics, HR Analytics , Marketing Analytics and Environmental Analytics
        </p>
      </VerticalTimelineElement>
      {/* <VerticalTimelineElement
        icon={<FaCaretDown/>}
        iconStyle={{ background: 'darkgreen', color: '#fff' }}
        iconOnClick={() => hideSection ? setHideSection(false) : setHideSection(true)} /> */}



      <VerticalTimelineElement
        className={`vertical-timeline-element--work ${hideSection ? 'hide-section' : ''}`}
        date="Jun 2020 - Nov 2020"
        iconStyle={{ background: '#FFB703', color: '#fff' }}
        icon={<FaBriefcase />}
        contentStyle={{ background: '#fff', color: '#fff', borderTop: '2px solid #FFB703' }}
        contentArrowStyle={{ borderRight: '7px solid  #FFB703' }}
      >
        <h3 className="vertical-timeline-element-title">International Relationship Manager</h3>
        <h4 className="vertical-timeline-element-subtitle">PK Shipping & Agency Co.,Ltd <span>- Bangkok, Thailand</span></h4>
        <ul>
          <li>
            <p>
              Collaborated and communicated with clients and suppliers from countries such as Bangladesh, China, and Singapore in order to facilitate the operation of projects focused on buying and selling of ships, hire of machinery and tools for road and port construction projects.
            </p>
          </li>
        </ul>

      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Jun 2017 - Dec 2019"
        iconStyle={{ background: '#FFB703', color: '#fff' }}
        icon={<FaBriefcase />}
        contentStyle={{ background: '#fff', color: '#fff', borderTop: '2px solid #FFB703' }}
        contentArrowStyle={{ borderRight: '7px solid  #FFB703' }}
        position={'right'}
      >
        <h3 className="vertical-timeline-element-title">Technical & Consulting Engineer</h3>
        <h4 className="vertical-timeline-element-subtitle">Keyence Corporation <span>- Bangkok, Thailand</span></h4>
        {/* <p>
          Setup, install, troubleshoot and program machine vision system including 2D/3D applications and vision guided robotics.
        </p> */}

        <ul>
          <li><p>Responsible for the whole cycle of sales and introduced new application of vision system to Thailand&#39;s market</p></li>
          <li><p>Setup, install, troubleshoot and program machine vision system including 2D/3D applications, vision-guided robotics, and communication with PLC sequence</p></li>

        </ul>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Aug 2013 - May 2017"
        iconStyle={{ background: '#219EBC', color: '#fff' }}
        icon={<FaGraduationCap />}
        contentStyle={{ background: '#fff', color: '#fff', borderTop: '3px solid #8ECAE6' }}
        contentArrowStyle={{ borderRight: '7px solid  #219EBC' }}
        position={'left'}
      >
        <h3 className="vertical-timeline-element-title">BSc Applied Chemistry</h3>
        <h4 className="vertical-timeline-element-subtitle">Chulalongkorn University <span>- Bangkok, Thailand</span></h4>
        <p>
          Industrial Management Major
        </p>
      </VerticalTimelineElement>

    </VerticalTimeline>
  )


}

export default Timeline