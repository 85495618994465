import { useEffect } from 'react'
import axios from 'axios'

import { Scrollbar } from 'smooth-scrollbar-react'
// import type { Scrollbar as BaseScrollbar } from "smooth-scrollbar/scrollbar"

import PageNavBar from './components/PageNavBar'
import Hero from './components/Hero'
import About from './components/About'
import Skills from './components/Skills'
import Projects from './components/Projects'
import ProjectNew from './components/ProjectNew'
import Experience from './components/Experience'
import Interests from './components/Interests'
import Footer from './components/Footer'

const App = () => {

  return (

    <div className='site-wrapper'>
      {/* <Scrollbar plugins={{
        overscroll: {
          effect: 'bounce',
        },
      }}> */}
      <PageNavBar></PageNavBar>

      <main>
        <Hero></Hero>
        <About></About>
        {/* <div className="background"></div> */}
        <Skills></Skills>
        {/* <Projects></Projects> */}
        <ProjectNew></ProjectNew>
        <Experience></Experience>
        <Interests></Interests>
      </main>
      <Footer></Footer>
      {/* </Scrollbar > */}
    </div >

  )


}

export default App
