import NavBar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import aromaticsImg from '../assets/aromatics.png'
import animoviesImg from '../assets/animovies.png'
import f1Img from '../assets/f1.png'
import tetrisImg from '../assets/tetris.png'
import aromaticsVid from '../assets/aromaticsVid.mov'
import animoviesVid from '../assets/animoviesVid.mov'
import formula1Vid from '../assets/formula1Vid.mov'
import tetrisVid from '../assets/tetrisVid.mov'


const ProjectNew = () => {
  return (
    <div id='projects' className='project-section-container'>
      <div id='projects' className='projects-new section-container'>
        <div id='projectWrap' className="section-wrapper">
          <div className='header-wrapper'>
            <h1 className='section-header'>Projects</h1>
            {/* <hr /> */}
          </div>
          {/* <h1 className='section-header mt-4'></h1> */}
          <Row className='all-projects'>
            <Col className='col-12'>
              <Row className='project-wrapper flex-row-reverse p-3 p-sm-4 pt-sm-4 pb-sm-4 mt-4'>
                <Col className='col-12 col-lg-6 image-wrapper mb-0 mb-xl-0'>
                  <video src={aromaticsVid} alt="Project Video" autoPlay muted loop><source src={aromaticsVid} /></video>
                  {/* <img src={aromaticsImg} alt="" /> */}
                </Col>
                <Col className='col-12 col-lg-6 info-container'>
                  <div className="info-wrapper p-0 pe-sm-5 ms-2 ms-sm-4">
                    <h4>Aromatics</h4>
                    <p>A React application with Django backend on aromatherapy with information about different essential oils along with its recipes. Built in 7 days as a pair.</p>
                    <p>  The user can login to create their own recipe, comment on other user’s recipes, bookmark or mark a recipe as tested. They can also view a list of recipes another user has created.</p>
                    <ul>
                      <li><i className="devicon-javascript-plain"></i></li>
                      <li><i className="devicon-react-original"></i></li>
                      {/* <li><i className="devicon-sass-plain"></i></li> */}
                      <li><i className="devicon-python-plain"></i></li>
                      <li><i className="devicon-postgresql-plain"></i></li>
                      <li><i className="devicon-django-plain"></i></li>
                      <li><i className="devicon-bootstrap-plain"></i></li>
                    </ul>
                    <div className="buttons pb-2 pb-sm-0">
                      <a href="https://github.com/fasaic/sei-Aromatics"><button><i className="devicon-github-plain"></i> Source Code</button></a>
                      <a href="https://aromatics.herokuapp.com/"><button>🔗 Project Link</button></a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className='col-12'>
              <Row className='project-wrapper flex-row-reverse p-3 p-sm-4 pt-sm-4 pb-sm-4'>
                <Col className='col-12 col-lg-6 image-wrapper mb-4 mb-xl-0'>
                  <video src={animoviesVid} alt="Project Video" autoPlay muted loop></video>
                </Col>
                <Col className='col-12 col-lg-6 info-container'>
                  <div className="info-wrapper p-0 pe-sm-5 ms-2 ms-sm-4">
                    <h4>Animovies</h4>
                    <p>A MERN application that aims to be a library and community for animated movies. Built in 7 days as a group of three. </p>
                    <p>Logged-in users can comment, and add animations to their watchlist, and view all the comments they have made compiled on their profile page. </p>
                    <ul>
                      <li><i className="devicon-javascript-plain"></i></li>
                      <li><i className="devicon-react-original"></i></li>
                      {/* <li><i className="devicon-sass-plain"></i></li> */}
                      <li><i className="devicon-nodejs-plain"></i></li>
                      <li><i className="devicon-mongodb-plain"></i></li>
                      <li><i className="devicon-express-original"></i></li>
                      {/* <li><i className="devicon-bootstrap-plain"></i></li> */}
                    </ul>
                    <div className="buttons pb-2 pb-sm-0">
                      <a href="https://github.com/fasaic/sei-Animovies-Frontend"><button><i className="devicon-github-plain"></i> Source Code</button></a>
                      <a href="https://animovies-ga.netlify.app/"><button>🔗 Project Link</button></a>
                    </div>
                  </div>
                </Col>

              </Row>
            </Col>
            <Col className='col-12'>
              <Row className='project-wrapper flex-row-reverse p-3 p-sm-4 pt-sm-4 pb-sm-4'>
                <Col className='col-12 col-lg-6 image-wrapper mb-4 mb-xl-0'>
                  {/* <img src={f1Img} alt="" /> */}
                  <video src={formula1Vid} alt="Project Video" autoPlay muted loop></video>

                  {/* <div className="overlay formula1">
              <a href="https://github.com/fasaic/sei-Formula1"><button><i className="devicon-github-plain"></i></button></a>
              <a href="https://formula1-seasonresults.netlify.app/"><button>🔗</button></a>
            </div> */}
                </Col>
                <Col className='col-12 col-lg-6 info-container'>
                  <div className="info-wrapper p-0 pe-sm-5 ms-2 ms-sm-4">
                    <h4>F1 - Results</h4>
                    <p>The React frontend application displaying the status of the Formula1 race (current season) consuming a public API. Built in 48 hours as a pair. </p>
                    <p>It shows current standings, race schedule and results, the drivers index, and brief drivers information.</p>
                    <ul>
                      <li><i className="devicon-javascript-plain"></i></li>
                      <li><i className="devicon-react-original"></i></li>
                      <li><i className="devicon-sass-plain"></i></li>
                      <li><i className="devicon-bootstrap-plain"></i></li>
                    </ul>
                    <div className="buttons pb-2 pb-sm-0">
                      <a href="https://github.com/fasaic/sei-Formula1"><button className=''><i className="devicon-github-plain"></i>Source Code</button></a>
                      <a href="https://formula1-seasonresults.netlify.app/"><button>🔗 Project Link</button></a>
                    </div>
                  </div>

                </Col>
              </Row>
            </Col>
            <Col className='col-12'>
              <Row className='project-wrapper flex-row-reverse p-3 p-sm-4 pt-sm-4 pb-sm-4'>
                <Col className='col-12 col-lg-6 image-wrapper mb-4 mb-xl-0'>
                  {/* <img src={tetrisImg} alt="" /> */}
                  <video src={tetrisVid} alt="Project Video" autoPlay muted loop></video>
                  {/* 
            <div className="overlay tetris">
              <a href="https://github.com/fasaic/sei-Tetris"><button><i className="devicon-github-plain"></i></button></a>
              <a href="https://fasaic.github.io/sei-Tetris/"><button>🔗</button></a>
            </div> */}
                </Col>
                <Col className='col-12 col-lg-6 info-container'>
                  <div className="info-wrapper p-0 pe-sm-5 ms-2 ms-sm-4">
                    <h4>Tetris</h4>
                    <p>A Tetris clone built with HTML, CSS, and Vanilla JavaScript. Built in 7 days by myself after three weeks of learning to code. </p>
                    <p> Tetris was a challenge to construct after three weeks, however I wanted to push my limits and learn the most out of this project.</p>
                    <ul>
                      <li><i className="devicon-html5-plain"></i></li>
                      <li><i className="devicon-css3-plain"></i></li>
                      <li><i className="devicon-javascript-plain"></i></li>
                    </ul>

                    <div className="buttons pb-2 pb-sm-0">
                      <a href="https://github.com/fasaic/sei-Tetris"><button><i className="devicon-github-plain"></i> Source Code</button></a>
                      <a href="https://fasaic.github.io/sei-Tetris/"><button>🔗 Project Link</button></a>
                    </div>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>




        </div>
      </div>
    </div>

  )
}

export default ProjectNew